import React from 'react'
import { Link } from 'gatsby'
import Layout from 'components/Layout'

const NotFound = () => (
  <Layout>
    <oma-grid-row class='row row--with-auto-margin'>
      <section className='section section--with-background'>
        <h2 className='section__heading'>Tyvärr, sidan finns inte.</h2>
        <br />
        <p className='section__text'>
          Gå tillbaka till{' '}
          <Link to='/' className='footer__link footer__link--inline-block'>
            startsidan.
          </Link>
        </p>
      </section>
    </oma-grid-row>
  </Layout>
)

export default NotFound
